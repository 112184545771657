import { CartState, initialCartState } from "./state";

import { ButtonPosition } from "./types";
import { VinoDataOffer } from "./../../types/vino-data-types";
import { createContext } from "react";

export interface CartContextInterface extends CartState {
  addItem: (
    cartItemId: string,
    offer: VinoDataOffer,
    customerID?: number | string,
    buttonPosition?: ButtonPosition
  ) => Promise<void>;
  updateItem: ({ cartItemId, productId, quantity }) => Promise<void>;
  removeItem: (cartItemId: string) => Promise<void>;
  addCoupon: (couponCode: string) => Promise<void>;
  removeCoupon: (couponCode: string) => Promise<void>;
  setCartIsOpen: (visible: boolean) => void;
  setGeoLocRegion: (region?: string) => Promise<void>;
  setCartStateEmail: (email?: string) => Promise<void>;
  handleRedirectToCheckout: ({ email }: { email?: string }) => Promise<void>;
  updateSoldOutProductList?: () => Promise<void>;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <CartProvider>.");
};

const initialContext = {
  ...initialCartState,
  addItem: stub,
  updateItem: stub,
  removeItem: stub,
  setCartIsOpen: stub,
  setCartStateEmail: stub,
  setGeoLocRegion: stub,
  addCoupon: stub,
  removeCoupon: stub,
  handleRedirectToCheckout: stub,
  updateSoldOutProductList: stub,
  cartTotal: 0,
  shippingTotal: 0,
  geoLocRegion: "",
};

export const CartContext = createContext<CartContextInterface>(initialContext);

export default CartContext;
