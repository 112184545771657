import { VinoUser } from "../contexts/auth/state";
import {
  LoyaltyConfig,
  LoyaltyTier,
  LoyaltyTierLowerCase,
} from "../contexts/loyalty/types";
import config from "../../config.json";
import { loyaltyTierNames } from "./constants";
interface LoyaltyAlgolia {
  index: string | undefined;
  tier?: LoyaltyTierLowerCase | null;
  tierFilter?: string | null;
}

export const isUserAStaff = (user: VinoUser) => {
  return (
    user &&
    user.customer_group &&
    user.customer_group.name === config.STAFF_GROUP_NAME
  );
};
export const determineTierIndex = (
  user: VinoUser,
  showLoyalty?: boolean,
  hbTier?: LoyaltyTier
): LoyaltyAlgolia => {
  if (!user || !showLoyalty || !hbTier) {
    return {
      tier: null,
      index: process.env.GATSBY_ALGOLIA_OFFERS_INDEX,
      tierFilter: "tiersEligible:GUEST OR tiersEligible:ALL",
    };
  }
  // const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
  let loyalty_tier: string | undefined = isUserAStaff(user)
    ? loyaltyTierNames.FOUNDERS
    : hbTier;
  if (loyalty_tier === "New") loyalty_tier = "none";

  const tierFilter = loyalty_tier 
    ? `tiersEligible:${loyalty_tier.toUpperCase()} OR tiersEligible:ALL`
    : `tiersEligible:GUEST OR tiersEligible:ALL`;

  return {
    tier: loyalty_tier ? loyalty_tier.toLocaleLowerCase() : "none",
    index: process.env.GATSBY_ALGOLIA_OFFERS_INDEX,
    tierFilter,
  };
};

interface TierCoupons {
  birthdayValue: number;
  anniversaryValue: number;
}

export const determineTierCouponValues = (
  config: LoyaltyConfig,
  tier: LoyaltyTier
): TierCoupons => {
  return {
    birthdayValue: config?.couponValues?.birthday?.[tier] || 0,
    anniversaryValue: config?.couponValues?.anniversary?.[tier] || 0,
  };
};
