import { Auth0CartStorage, CartStorage, LocalCartStorage } from "./store";
import {
  BIG_COMMERCE_CART_ID_COOKIE,
  GUEST_CART_EMAIL_COOKIE,
  GUEST_CHECKOUT_PATH,
} from "../../utils/constants";
import CartContext, { CartContextInterface } from "./context";
import { CartResolver, checkInventoryLevel } from "./cart-resolver";
import {
  trackCartCouponEvent,
  trackOfferDetailsEvent,
} from "../../utils/event-tracking";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { ButtonPosition } from "./types";
import { VinoDataOffer } from "../../types/vino-data-types";
import config from "../../../config.json";
import contextLogger from "../../utils/context-logger";
import { initialCartState } from "./state";
import { isCouponForLoggedInUser } from "../../utils/is-coupon-for-loggedin-user";
import { navigate } from "gatsby";
import { reducer } from "./reducer";
import useAuth from "../../hooks/use-auth";
import { useLocation } from "@reach/router";
import useOneSignal from "../../hooks/use-onesignal";
import vinoFetch from "../../utils/vinoFetch";
import { EventSubType, EventType } from "../../types";

type CartProviderProps = React.PropsWithChildren<{ initialCartId?: string }>;

const MAX_FETCH_STORE_RETRIES = 10;

export const CartProvider = ({ children }: CartProviderProps): JSX.Element => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qCartId = queryParams.get("cartId");
  const emailParam = new URLSearchParams(location.search).get("email");
  const { queryID } = (location.state as { queryID?: string }) || {};
  const [state, dispatch] = useReducer(
    process.env.GATSBY_VM_ENV !== "production"
      ? contextLogger(reducer)
      : reducer,
    initialCartState
  );
  const [store, setStore] = useState<CartStorage>(undefined);
  const [addItemQueue, setAddItemQueue] = useState<
    Array<{
      cartItemId?: string;
      offer?: VinoDataOffer;
      customerId?: number;
    }>
  >([]);
  const { updateTagsOnCartChange } = useOneSignal();
  const {
    user,
    isAuthenticated,
    getAccessToken,
    getTokenID,
    isLoading: isAuthenticating,
  } = useAuth();
  const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
  const customer_bigcommerce_id =
    userAppMeta?.[`BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CUSTOMER_ID`];

  const setBigCommerceId = async (): Promise<any> => {
    if (!customer_bigcommerce_id && user?.email) {
      // This is because the auth0 functions are still creating the customer in the background. So get it straight from BigCommerce
      try {
        const bcCustomer = await vinoFetch.get(
          "/api/get-customer-id-by-email",
          {
            params: {
              email: user.email,
              first_name: user.given_name,
              last_name: user.family_name,
            },
          }
        );
        return bcCustomer?.data[0]?.id;
      } catch (error) {
        return 0;
      }
    } else {
      return customer_bigcommerce_id;
    }
  };

  const getGeoLocation = async (): Promise<any> => {
    return vinoFetch.get(
      `${process.env.GATSBY_VINO_EDGE_FUNCTIONS_HOST}/edge/geolocation`
    );
  };

  const cartResolver = CartResolver;

  const fetchStore = async () => {
    let newStore;

    if (isAuthenticated) {
      const accessToken = await getAccessToken();
      const tokenID = await getTokenID();
      newStore = Auth0CartStorage(accessToken, tokenID);
    } else if (isAuthenticating || state?.isMerging) {
      newStore = undefined;
    } else {
      newStore = LocalCartStorage(
        BIG_COMMERCE_CART_ID_COOKIE,
        GUEST_CART_EMAIL_COOKIE
      );
    }

    setStore(newStore);
  };

  const handleCartMerging = async () => {
    dispatch({ type: "MERGE_CART_STARTED", error: null });

    const cookieCartId = await LocalCartStorage(
      BIG_COMMERCE_CART_ID_COOKIE
    ).fetchCartId();

    const storeCartId = await store.fetchCartId();

    let updatedCartId;
    let redirectToCheckout = false;

    if (
      isAuthenticated &&
      cookieCartId &&
      storeCartId &&
      cookieCartId !== storeCartId
    ) {
      const {
        cart: dormantCart,
        error: dormantCartError,
      } = await cartResolver.loadCart({
        cartId: storeCartId,
        customerId: customer_bigcommerce_id ? customer_bigcommerce_id : 0,
        geoLocRegion: state.geoLocRegion,
      });

      if (dormantCart?.withSoldOutItem) {
        const soldOutProducts = dormantCart.lineItems.filter(
          (item) => item.isSoldOut
        );
        dispatch({
          type: "UPDATE_SOLD_OUT_PRODUCTS",
          soldOutProducts: soldOutProducts,
        });

        const promiseAll = soldOutProducts.map(async (item) => {
          const { cart } = await cartResolver.removeCartItem({
            cartId: storeCartId,
            cartItemId: item.id,
            geoLocRegion: state.geoLocRegion,
          });
          return cart;
        });
        await Promise.all(promiseAll).then((res) => {
          console.log(
            "ALL SOLD-OUT PRODUCTS HAVE BEEN REMOVED FROM THE CART.",
            res
          );
        });
      }

      dispatch({ type: "CREATE_CART_STARTED", error: null });
      const cartIds = dormantCart
        ? [storeCartId, cookieCartId]
        : [cookieCartId];
      const { cart, error } = await cartResolver.mergeCarts({
        cartIds,
        undefined,
        geoLocRegion: state.geoLocRegion,
      });

      if (error) {
        dispatch({ type: "MERGE_CART_COMPLETED" });
        return;
      }

      updatedCartId = cart.id;
      redirectToCheckout = dormantCart ? false : true; // Redirect user straight to checkout when dormant cart is invalid

      dispatch({
        type: "CREATE_CART_COMPLETED",
        cart,
        isCartOpen: true,
        changeCartOnMerge: true,
        fetchingCart: true,
      });

      // 2023-10-06: Commented out deletion of the cart to eliminate 500 errors and since we update the latest cartId anyway.
      // cartResolver.destroyCart({ cartId: cookieCartId });
      // cartResolver.destroyCart({ cartId: storeCartId });
    } else {
      updatedCartId = storeCartId || cookieCartId;
      redirectToCheckout = true;

      // Merge cart ID from url queary parameter and the cookieCartId for guest users
      if (qCartId && !isAuthenticated) {
        let responseCart;
        let responseError;
        const { cart: checkCart } = await cartResolver.checkCart({
          cartId: qCartId,
          geoLocRegion: state.geoLocRegion,
        });

        if (checkCart && cookieCartId !== qCartId) {
          const cartIds = [cookieCartId];
          const { cart, error } = await cartResolver.mergeCarts({
            cartIds,
            cartId: qCartId,
            geoLocRegion: state.geoLocRegion,
          });

          responseError = error;
          responseCart = cart;

          if (responseError) {
            dispatch({ type: "MERGE_CART_COMPLETED" });
            return;
          }

          updatedCartId = responseCart.id;
          // 2023-10-06: Commented out deletion of the cart to eliminate 500 errors and since we update the latest cartId anyway.
          // await cartResolver.destroyCart({ cartId: cookieCartId });
        }
        // Remove the cart ID from the URL
        const updatedUrl = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, updatedUrl);
      }
    }

    // Only update the cart if it's changing
    if (updatedCartId && updatedCartId !== storeCartId) {
      await store.setCartId(updatedCartId);
      if (redirectToCheckout) await handleRedirectToCheckout({ instant: true });
    }

    // Clear Cookie store if we're now logged in
    if (isAuthenticated) {
      const _store = LocalCartStorage(
        BIG_COMMERCE_CART_ID_COOKIE,
        GUEST_CART_EMAIL_COOKIE
      );
      _store.removeCartId();
      if (_store.removeGuestCartEmail) _store.removeGuestCartEmail();
    }

    dispatch({ type: "MERGE_CART_COMPLETED" });
  };

  const processAddItemsQueue = async () => {
    if (!store) return;
    const failures = [];

    addItemQueue.map(async ({ cartItemId, offer, customerId }) => {
      try {
        await handleAddItem(cartItemId, offer, customerId);
      } catch {
        failures.push({ cartItemId, offer, customerId });
      }
    });

    // Re-add any failures to the list
    setAddItemQueue(failures);
  };

  const waitForStore = async (): Promise<{ error?: Error }> => {
    let error: Error;
    let retries = 0;
    while (!store && retries < MAX_FETCH_STORE_RETRIES) {
      await new Promise((resolve) => {
        retries++;
        return setTimeout(resolve, 300);
      });
    }

    // Throw if max retries exceeded and still no store
    if (!store) error = new Error("Could not load cart storage provider");

    return { error };
  };

  const loadCart = async () => {
    dispatch({ type: "FETCH_CART_STARTED", error: null });

    const cartId = await store.fetchCartId();
    if (!cartId) {
      return dispatch({
        type: "FETCH_CART_COMPLETED",
        cart: null,
      });
    }

    const cust_id = await setBigCommerceId();

    const { cart, error } = await cartResolver.loadCart({
      cartId,
      customerId: cust_id ? cust_id : 0,
      geoLocRegion: state.geoLocRegion,
    });

    if (!cart) {
      await store.removeCartId();
      if (store.removeGuestCartEmail) await store.removeGuestCartEmail();
    }

    if (error) {
      dispatch({ type: "ERROR", error: error });
      return;
    }
    dispatch({ type: "FETCH_CART_COMPLETED", cart });
  };

  const setCartStateEmail = async (email?: string) => {
    if (emailParam && email) {
      const _store =
        store ||
        LocalCartStorage(BIG_COMMERCE_CART_ID_COOKIE, GUEST_CART_EMAIL_COOKIE);

      if (email) {
        await _store.setGuestCartEmail(email);
        return dispatch({ type: "UPDATE_CART_EMAIL", email });
      }

      const cookieCartEmail = await _store.getGuestCartEmail();
      if (emailParam && emailParam !== state.cart?.customerEmail) {
        await _store.setGuestCartEmail(emailParam);
        dispatch({ type: "UPDATE_CART_EMAIL", email: emailParam });
      } else if (cookieCartEmail) {
        dispatch({ type: "UPDATE_CART_EMAIL", email: cookieCartEmail });
      }
    }
  };

  const handleRedirectToCheckout = async ({
    email,
    instant = false,
  }: {
    email?: string;
    instant?: boolean;
  }) => {
    dispatch({ type: "HANDLE_REDIRECT_TO_CHECKOUT_STARTED", error: null });

    if (state.cart)
      localStorage.setItem(
        "isGuest",
        state.cart.customerId === 0 ? "yes" : "no"
      );

    if (!isAuthenticated && !email) {
      dispatch({
        type: "HANDLE_REDIRECT_TO_CHECKOUT_COMPLETED",
        isRedirecting: false,
      });
      navigate(GUEST_CHECKOUT_PATH);
      return;
    }

    const accessToken = isAuthenticated ? await getAccessToken() : undefined;
    const cartId = await store.fetchCartId();
    const updateEmail = await CartResolver.updateGuestCartEmail({
      email,
      cartId,
    });
    if (updateEmail.error) {
      dispatch({ type: "ERROR", error: updateEmail.error });
      return;
    }

    if (!instant) {
      const cust_id = await setBigCommerceId();
      const { cart } = await cartResolver.loadCart({
        cartId,
        customerId: cust_id ? cust_id : 0,
        geoLocRegion: state.geoLocRegion,
      });

      const data = await checkInventoryLevel(cart);

      if (data) {
        const productsSoldOut = data.lineItems.some(
          (x) => x.isSoldOut === true || x.availableStock
        );

        if (productsSoldOut.length > 0) {
          navigate("/cart/");
          return;
        }
      }

      if (cart?.customerId === 0 && store.removeGuestCartEmail)
        await store.removeGuestCartEmail();
    }

    const { checkoutUrl, error } = await cartResolver.loadCheckoutUrl({
      userId: user?.sub,
      accessToken,
      email,
      cartId,
    });

    if (error) {
      dispatch({ type: "ERROR", error });
      return;
    }

    window.location.assign(checkoutUrl);
    dispatch({
      type: "HANDLE_REDIRECT_TO_CHECKOUT_COMPLETED",
      isRedirecting: true,
    });
  };

  const handleUpdateItem = async ({ cartItemId, productId, quantity }) => {
    dispatch({ type: "UPDATE_ITEM_STARTED", error: null });

    const cartId = await store.fetchCartId();

    const { cart, error } = await cartResolver.updateCartItem({
      cartId,
      cartItemId,
      productId,
      quantity,
      geoLocRegion: state.geoLocRegion,
    });

    if (error) {
      dispatch({
        type: "ERROR",
        error: error,
      });
      return;
    }

    dispatch({ type: "UPDATE_ITEM_COMPLETED", cart });
  };

  const handleRemoveItem = async (cartItemId: string) => {
    dispatch({ type: "REMOVE_ITEM_STARTED", error: null });

    const cartId = await store.fetchCartId();

    if (!cartId) {
      dispatch({
        type: "REMOVE_ITEM_COMPLETED",
        cart: null,
        isCartOpen: false,
      });
      return;
    }

    const { cart, error } = await cartResolver.removeCartItem({
      cartId,
      cartItemId,
      geoLocRegion: state.geoLocRegion,
    });

    if (error) {
      dispatch({ type: "ERROR", error: error });
      return;
    }

    updateTagsOnCartChange(cart);

    if (!cart) {
      dispatch({ type: "REMOVE_ITEM_COMPLETED", cart, isCartOpen: false });

      await store.removeCartId();
      if (store.removeGuestCartEmail) await store.removeGuestCartEmail();
      return;
    }

    dispatch({ type: "REMOVE_ITEM_COMPLETED", cart });
  };

  const handleCreateCart = async ({
    customerId,
    cartItemId,
    offer,
  }: {
    customerId?: number;
    cartItemId: string;
    offer?: VinoDataOffer;
  }) => {
    dispatch({ type: "CREATE_CART_STARTED", error: null });
    const { cart, error } = await cartResolver.createCart({
      customerId,
      productId: cartItemId,
      offer,
      geoLocRegion: state.geoLocRegion,
    });

    if (error) {
      trackOfferDetailsEvent("Product Added - Ineligible", {
        ...offer,
        customer_group: user?.customer_group?.name || "No Group",
      });
      dispatch({ type: "ERROR", error: error });
      return;
    }

    const { error: fetchStoreError } = await waitForStore();
    if (fetchStoreError) {
      dispatch({ type: "ERROR", error: fetchStoreError });
      return;
    }

    await store.setCartId(cart.id);

    dispatch({ type: "CREATE_CART_COMPLETED", cart, isCartOpen: true });
    updateTagsOnCartChange(cart);
  };

  const handleAddItem = async (
    cartItemId?: string,
    offer?: VinoDataOffer,
    customerId?: number,
    buttonPosition?: ButtonPosition
  ) => {
    if (!store || state.isLoading) {
      const itemToBeAdded = { cartItemId, offer, customerId };
      setAddItemQueue([...addItemQueue, itemToBeAdded]);
      return;
    }

    dispatch({ type: "ADD_ITEM_STARTED", error: null });

    const cartId = await store.fetchCartId();
    const accessToken = isAuthenticated ? await getAccessToken() : undefined;
    const { cart, error } = await cartResolver.addCartItem({
      customerId: customerId ? customerId : undefined,
      cartId,
      cartItemId,
      accessToken,
      offer,
      geoLocRegion: state.geoLocRegion,
    });

    if (error) {
      trackOfferDetailsEvent("Product Added - Ineligible", {
        ...offer,
        customer_group: user?.customer_group?.name || "No Group",
      });
      dispatch({ type: "ERROR", error: error });
      return;
    }

    if (cart) {
      dispatch({ type: "ADD_ITEM_COMPLETED", cart });
      updateTagsOnCartChange(cart);
    } else {
      handleCreateCart({
        customerId: customerId ? customerId : undefined,
        cartItemId: cartItemId,
        offer,
      });
    }

    const cartItem = cart?.lineItems.find(
      (i) =>
        i.productId ===
          parseInt(offer.productId?.toString() || offer.bigcommerceId) &&
        i.name === offer.name &&
        i.quantity > 1
    );

    if (cartItem) {
      trackOfferDetailsEvent(
        "Product Quantity Increased",
        {
          eventType: EventType.Conversion,
          eventSubType: EventSubType.AddToCart,
          cartId: cartId,
          name: cartItem.name,
          productId: cartItem.productId,
          quantity: cartItem.quantity,
          new_quantity: cartItem.quantity + 1,
          SKU: cartItem.sku,
          changed_by: 1,
          imageUrl: cartItem.imageUrl,
          url: `${location.origin}/wines${cartItem.slug}`,
          customer_group: user?.customer_group?.name || "No Group",
        },
        buttonPosition,
        offer?.queryID || queryID
      );
    } else {
      trackOfferDetailsEvent(
        "Product Added",
        {
          eventType: EventType.Conversion,
          eventSubType: EventSubType.AddToCart,
          ...offer,
          cartId: cartId,
          customer_group: user?.customer_group?.name || "No Group",
        },
        buttonPosition,
        offer?.queryID || queryID
      );
    }
  };

  const setCartIsOpen = (visible: boolean) => {
    if (visible !== state?.isCartOpen)
      dispatch({ type: "UPDATE_CART_OPEN", isCartOpen: visible, error: null });
  };

  const setGeoLocRegion = (region: string) => {
    dispatch({ type: "SET_GEO_LOC_REGION", region });
  };

  const addItem = async (cartItemId, offer, customerId, buttonPosition) => {
    return handleAddItem(cartItemId, offer, customerId, buttonPosition);
  };

  const removeItem = useCallback(
    (cartItemId): Promise<void> => {
      return handleRemoveItem(cartItemId);
    },
    [store]
  );

  const updateItem = useCallback(
    (args): Promise<void> => {
      return handleUpdateItem(args);
    },
    [store]
  );

  const initialLoad = async () => {
    await handleCartMerging();
    if (!state.isRedirecting) await loadCart();
  };

  const handleAddCoupon = async (couponCode?: string) => {
    if (!store || state.isLoading) {
      return;
    }

    dispatch({ type: "ADD_COUPON_STARTED", error: null });

    if (state.cart.cartTotal < config.MINIMUM_VOUCHER_REQUIREMENT) {
      const customError = {
        name: "Error",
        message: config.MINIMUM_VOUCHER_ERROR_MESSAGE,
        stack: "coupon",
      };
      trackCartCouponEvent(
        "Coupon Applied",
        location.pathname,
        state.cart,
        couponCode,
        null,
        customError.message
      );
      dispatch({ type: "ERROR", error: customError });
      return customError;
    }

    const cartId = await store.fetchCartId();
    const accessToken = isAuthenticated ? await getAccessToken() : undefined;

    const promotionByCode = couponCode
      ? await CartResolver.getPromotionByCode(couponCode)
      : null;
    const { withGiftCard } = state.cart;

    if (withGiftCard) {
      const customError = {
        name: "Error",
        message: config.GC_TO_GC_ERROR,
        stack: "coupon",
      };
      trackCartCouponEvent(
        "Coupon Applied",
        location.pathname,
        state.cart,
        couponCode,
        null,
        customError.message
      );
      dispatch({ type: "ERROR", error: customError });
      return config.GC_TO_GC_ERROR;
    }

    if (
      state.cart &&
      parseInt(state.cart.customerId) === 0 &&
      promotionByCode &&
      promotionByCode.length > 0
    ) {
      const promotionItem = promotionByCode[0];
      if (isCouponForLoggedInUser(promotionItem)) {
        const guestUserErr =
          "Hmmm. You need to be signed-in to try this coupon. Why not join/login and try again?";

        const customError = {
          name: "Error",
          message: guestUserErr,
          stack: "guest-coupon",
        };
        trackCartCouponEvent(
          "Coupon Applied",
          location.pathname,
          state.cart,
          couponCode,
          null,
          customError.message
        );
        dispatch({ type: "ERROR", error: customError });
        return guestUserErr;
      }
    }

    if (
      parseInt(customer_bigcommerce_id) !== 0 &&
      promotionByCode &&
      promotionByCode[0]?.name === `Referral_code_${customer_bigcommerce_id}`
    ) {
      const selfReferralErr = "You cannot refer yourself.";

      const customError = {
        name: "Error",
        message: selfReferralErr,
        stack: "coupon",
      };
      trackCartCouponEvent(
        "Coupon Applied",
        location.pathname,
        state.cart,
        couponCode,
        null,
        customError.message
      );
      dispatch({ type: "ERROR", error: customError });
      return selfReferralErr;
    }

    const { cart, error } = await cartResolver.addCoupon({
      cartId,
      couponCode,
      accessToken,
      geoLocRegion: state.geoLocRegion,
    });
    console.log("error ", error?.response);

    if (error) {
      const errorData = error?.response?.data;
      let errMsg = errorData.title?.replace(/(<([^>]+)>)/gi, "");
      if (
        errMsg === "You are not in the customer group the promotion targets"
      ) {
        errMsg = config.INVALID_COUPON_ELIGIBILITY_MESSAGE;
      } else if (
        couponCode &&
        errMsg === `The coupon code ${couponCode} you entered is not valid.`
      ) {
        errMsg = config.INVALID_COUPON_MESSAGE.replace("<code>", couponCode);
      } else if (errMsg.indexOf(`The coupon code you entered expired`) > -1) {
        errMsg = config.EXPIRED_COUPON_MESSAGE;
      } else {
        errMsg = config.INVALID_COUPON_MESSAGE.replace("<code>", couponCode);
      }

      const customError = {
        name: "Error",
        message: errMsg || config.INVALID_COUPON_MESSAGE,
        stack: "coupon",
      };
      trackCartCouponEvent(
        "Coupon Applied",
        location.pathname,
        state.cart,
        couponCode,
        null,
        customError.message
      );
      dispatch({ type: "ERROR", error: customError });
      return error;
    }

    if (cart) {
      dispatch({ type: "ADD_COUPON_COMPLETED", cart });
      return cart;
    }
  };

  const handleRemoveCoupon = async (couponCode: string) => {
    dispatch({ type: "REMOVE_COUPON_STARTED", error: null });

    const cartId = await store.fetchCartId();

    const { cart, error } = await cartResolver.removeCoupon({
      cartId,
      couponCode,
      geoLocRegion: state.geoLocRegion,
    });

    if (error) {
      const errorData = error?.response?.data;
      let errMsg = errorData.title?.replace(/(<([^>]+)>)/gi, "");
      if (
        couponCode &&
        errMsg === `The coupon code ${couponCode} you entered is not valid.`
      ) {
        errMsg = config.INVALID_COUPON_MESSAGE.replace("<code>", couponCode);
      } else if (errMsg.indexOf(`The coupon code you entered expired`) > -1) {
        errMsg = config.EXPIRED_COUPON_MESSAGE;
      } else {
        errMsg = config.INVALID_COUPON_MESSAGE.replace("<code>", couponCode);
      }

      const customError = {
        name: "Error",
        message: errMsg,
        stack: "coupon",
      };
      dispatch({ type: "ERROR", error: customError });
      return;
    }

    updateTagsOnCartChange(cart);

    if (!cart) {
      dispatch({ type: "REMOVE_COUPON_COMPLETED", cart, isCartOpen: false });

      await store.removeCartId();
      if (store.removeGuestCartEmail) await store.removeGuestCartEmail();
      return;
    }

    dispatch({ type: "REMOVE_COUPON_COMPLETED", cart });
  };

  const addCoupon = async (couponCode) => {
    return await handleAddCoupon(couponCode);
  };

  const removeCoupon = useCallback(
    (couponCode): Promise<void> => {
      return handleRemoveCoupon(couponCode);
    },
    [store]
  );

  const updateSoldOutProductList = () => {
    dispatch({ type: "UPDATE_SOLD_OUT_PRODUCTS", soldOutProducts: [] });
  };

  const ctx: CartContextInterface = useMemo(
    () => ({
      ...state,
      addItem,
      updateItem,
      removeItem,
      setCartIsOpen,
      addCoupon,
      removeCoupon,
      handleRedirectToCheckout,
      setCartStateEmail,
      setGeoLocRegion,
      updateSoldOutProductList,
    }),
    [
      state,
      addItem,
      removeItem,
      updateItem,
      setCartIsOpen,
      addCoupon,
      removeCoupon,
      setGeoLocRegion,
      updateSoldOutProductList,
      store,
    ]
  );

  const fetchGeoLocation = async () => {
    const geoLocation = await getGeoLocation();
    if (geoLocation?.data?.geo) {
      await setGeoLocRegion(geoLocation?.data?.geo?.subdivision?.code);
      localStorage.setItem(
        "vm-geolocation",
        JSON.stringify(geoLocation?.data?.geo)
      );
    }
  };

  useEffect(() => {
    if (state.error && state.isLoading) {
      delete state.error;
      dispatch({ ...state, type: "RESET_ERROR", error: null });
    }

    if (store && !state.isLoading) processAddItemsQueue();
  }, [state]);

  useEffect(() => {
    if (store && state && !state.isLoading && !state.isMerging) {
      initialLoad();
    }
  }, [store]);

  useEffect(() => {
    if (!store) fetchStore();
    if (state.geoLocRegion === "") fetchGeoLocation();
  }, [isAuthenticating, isAuthenticated]);

  return <CartContext.Provider value={ctx}>{children}</CartContext.Provider>;
};

export default CartProvider;
