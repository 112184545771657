import { Cart, CartItem } from "./types";

import { CartState } from "./state";
import { logError } from "../../utils/logger";

export type Action =
  | { type: "CREATE_CART_STARTED" }
  | { type: "CREATE_CART_COMPLETED"; cart: Cart; isCartOpen?: boolean; changeCartOnMerge?: boolean; fetchingCart?: boolean }
  | { type: "FETCH_CART_STARTED" }
  | { type: "FETCH_CART_COMPLETED"; cart: Cart }
  | { type: "MERGE_CART_STARTED" }
  | { type: "MERGE_CART_COMPLETED" }
  | { type: "ADD_ITEM_STARTED" }
  | { type: "ADD_ITEM_COMPLETED"; cart: Cart; isCartOpen?: boolean }
  | { type: "UPDATE_ITEM_STARTED" }
  | { type: "UPDATE_ITEM_COMPLETED"; cart: Cart; isCartOpen?: boolean }
  | { type: "REMOVE_ITEM_STARTED" }
  | { type: "REMOVE_ITEM_COMPLETED"; cart: Cart; isCartOpen?: boolean }
  | { type: "HANDLE_REDIRECT_TO_CHECKOUT_STARTED" }
  | { type: "HANDLE_REDIRECT_TO_CHECKOUT_COMPLETED"; isRedirecting: boolean }
  | { type: "UPDATE_CART_OPEN"; isCartOpen: boolean }
  | { type: "UPDATE_CART_EMAIL"; email: string }
  | { type: "SET_GEO_LOC_REGION"; email: string }
  | { type: "ADD_COUPON_STARTED" }
  | { type: "ADD_COUPON_COMPLETED"; cart: Cart; isCartOpen?: boolean }
  | { type: "REMOVE_COUPON_STARTED" }
  | { type: "REMOVE_COUPON_COMPLETED"; cart: Cart; isCartOpen?: boolean }
  | { type: "UPDATE_SOLD_OUT_PRODUCTS", soldOutProducts: Array<CartItem> }
  | { type: "ERROR"; error: Error };

export const reducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case "ADD_ITEM_STARTED":
    case "UPDATE_ITEM_STARTED":
    case "REMOVE_ITEM_STARTED":
    case "ADD_COUPON_STARTED":
    case "REMOVE_COUPON_STARTED":
      return {
        ...state,
        isLoading: true,
      };
      

    case "CREATE_CART_STARTED":
    case "FETCH_CART_STARTED":
      return {
        ...state,
        isLoading: true,
        fetchingCart: true,
      };

    case "CREATE_CART_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isCartOpen:
          action.isCartOpen !== undefined
            ? action.isCartOpen
            : state.isCartOpen,
        isLoading: false,
        changeCartOnMerge: action.changeCartOnMerge,
        fetchingCart: action.fetchingCart,
      };

    case "FETCH_CART_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isCartOpen: false,
        isLoading: false,
        fetchingCart: false,
      };

    case "ADD_ITEM_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isCartOpen: false,
        isLoading: false,
      };

    case "UPDATE_ITEM_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isCartOpen:
          action.isCartOpen !== undefined
            ? action.isCartOpen
            : state.isCartOpen,
        isLoading: false,
      };

    case "REMOVE_ITEM_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isLoading: false,
      };

    case "HANDLE_REDIRECT_TO_CHECKOUT_STARTED":
      return {
        ...state,
        isRedirecting: true,
      };

    case "HANDLE_REDIRECT_TO_CHECKOUT_COMPLETED":
      return {
        ...state,
        isRedirecting: action.isRedirecting,
        isCartOpen: false,
      };

    case "UPDATE_CART_OPEN":
      return {
        ...state,
        isCartOpen: action.isCartOpen,
      };

    case "UPDATE_CART_EMAIL":
      return {
        ...state,
        cart: { ...state.cart, customerEmail: action.email },
      };

    case "SET_GEO_LOC_REGION":
      return {
        ...state,
        geoLocRegion: action.region,
      };

    case "MERGE_CART_STARTED":
      return {
        ...state,
        isMerging: true,
      };

    case "MERGE_CART_COMPLETED":
      return {
        ...state,
        isMerging: false,
      };

    case "ADD_COUPON_COMPLETED":
    case "REMOVE_COUPON_COMPLETED":
      return {
        ...state,
        cart: action.cart,
        isLoading: false,
      };

    case "UPDATE_SOLD_OUT_PRODUCTS":
      return {
        ...state,
        soldOutProducts: action.soldOutProducts,
      };

    case "ERROR":
      logError(action.error.message, { error: action.error });
      return {
        ...state,
        isLoading: false,
        isRedirecting: false,
        error: action.error,
      };

    default:
      return state;
  }
};
